// Root imports
import React from "react";
import ReactDOM from "react-dom/client";

// App's components
import MainPage from "./components/MainPage.js";

// App's styles
import "./index.css";

// Getting html main container for the application
const root = ReactDOM.createRoot(document.getElementById("root"));

// General React rendering
root.render(
  <React.StrictMode>
    {/* Global container for application */}
    <div className="App">
      <MainPage />
    </div>
  </React.StrictMode>
);
