// Root imports
import React from "react";

// Style for the component
import "../css/footer.css";

// Static content import
import logo from "../static/logo_prodet.png";
import TikTokIcon from "../static/TikTokIcon.svg";

// Third party icons
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = (props) => {
  // Method to open email form
  const handleFormOpen = () => {
    props.handleFormOpen();
  };

  return (
    <div className="footer-section">
      {/* Main texts */}
      <div className="footer-texts">
        <div className="footer-title">CONÓCENOS</div>
        <div className="footer-subtitle">
          En R&DC Computación desarrollamos software para generar nuevas formas
          de abordar el problema del diseño en concreto reforzado conectando con
          los problemas reales de los constructores.
        </div>
      </div>

      <div className="footer-texts">
        <div className="footer-title">CONTÁCTANOS</div>
        <div className="footer-subtitle">
          Cra. 35 #15b-35
          <br />
          Edificio Prisma, Oficina 9913
          <br />
          Medellín, Colombia
          <br />
          Teléfono: +57 3001447296
          <br />
          j.parra@rdc-consultores.com
          <br />
        </div>
      </div>

      <div className="footer-texts">
        <div className="footer-title">¡SOLICITA YA!</div>
        <div className="footer-subtitle">
          <span className="footer-form" onClick={handleFormOpen}>
            ¡Inscríbete aquí!
          </span>
          <br />
          <br />
          Haz parte de nuestra comunidad. Haz parte del futuro de la ingeniería estructural.
        </div>
      </div>

      {/* Last section = logo + social media icons */}
      <div className="last-section-logo">
        {/* Final logo */}
        <div className="final-logo-container">
          <img className="final-img" src={logo} alt="logo" />
          <span className="final-logo-text">ProDet</span>
        </div>
        <div className="footer-subtitle">¡SÍGUENOS!</div>

        {/* Social media links */}
        <div className="social-icons">
          <a
            href="https://www.youtube.com/channel/UCem82-GYG6mkfxwpTtt2gDw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YouTubeIcon fontSize="large" className="media-icon" />
          </a>
          <a
            href="https://www.instagram.com/prodet_projectdetailer/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon fontSize="large" className="media-icon" />
          </a>
          <a
            href="https://www.linkedin.com/company/prodet/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon fontSize="large" className="media-icon" />
          </a>
          <a
            href="https://www.tiktok.com/@projectdetailer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={TikTokIcon} alt="TikTok" style={{ width: "35px" }} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
