// Root imports
import React, { useState, useEffect } from "react";

// Style for the component
import "../css/advantages.css";

// Static content import
import logo from "../static/logo.svg";

// List of ProDes advantages
const advantages = [
  {
    title: "Mejorar el diseño conceptual",
    content: `Gracias a ProDet podrás implementar otros paradigmas en el diseño
       estructural, ahorrar tiempo para enfocarte en la concepción de 
       la estructura y su modelación, y descargar gran parte del proceso de 
       dibujo en el computador.`,
  },
  {
    title: "Mejorar el reforzamiento",
    content: `Con ProDet podrás considerar detalles que sólo un software de diseño puede ofrecer.
    Por ejemplo podrás incluir refuerzos constructivos, aplicar redistribución de momentos, despiezar
    con empalmes mecánicos, y muchas consideraciones más.`,
  },
  {
    title: "Crear grupos de pisos y elementos",
    content: `Con ProDet y tan sólo unos cuantos clicks, podrás generar despieces
    agrupando pisos y elementos geométricamente idénticos para simplificar el proceso constructivo.`,
  },

  {
    title: "Apostar a los desarrollos futuros ",
    content: `Al adquirir la suscripción de ProDet tendrás beneficios para adquirir todos los
    desarrollos futuros. Próximamente podrás considerar edificios con muros y/o
    columnas bajo el mismo paradigma de trabajo.`,
  },
];

// Icons for the indicators
const ClockIcon = () => (
  <lord-icon
    src="https://cdn.lordicon.com/mwikjdwh.json"
    trigger="loop"
    state="loop-clock"
    colors="primary:#ffffff"
  ></lord-icon>
);

const DecreaseIcon = () => (
  <lord-icon
    src="https://cdn.lordicon.com/utqytqrt.json"
    trigger="loop"
    delay="1000"
    state="in-trend-down"
    colors="primary:#ffffff"
  ></lord-icon>
);

const AreaIcon = () => (
  <lord-icon
    src="https://cdn.lordicon.com/vdjwmfqs.json"
    trigger="loop"
    delay="1000"
    colors="primary:#ffffff"
  ></lord-icon>
);

const BuildingIcon = () => (
  <lord-icon
    src="https://cdn.lordicon.com/cnpvyndp.json"
    trigger="loop"
    delay="1000"
    state="in-home"
    colors="primary:#ffffff"
  ></lord-icon>
);

const WorkIcon = () => (
  <lord-icon
    src="https://cdn.lordicon.com/ppyvfomi.json"
    trigger="loop"
    delay="1000"
    state="in-work"
    colors="primary:#ffffff"
  ></lord-icon>
);

// List of indicators
const listIndicators = [
  {
    icon: ClockIcon,
    indicator: "+7 Años",
    text: "de constante desarrollo y validación",
  },
  {
    icon: DecreaseIcon,
    indicator: "90% Menos",
    text: "tiempo en detallado de refuerzo",
  },
  {
    icon: AreaIcon,
    indicator: "+1.5M m²",
    text: "diseñados",
  },
  {
    icon: BuildingIcon,
    indicator: "+100 Edificios",
    text: "diseñados",
  },
  {
    icon: WorkIcon,
    indicator: "+20 Empresas",
    text: "impactadas",
  },
];

const AdvantagesSection = () => {
  // Local state
  const [selectedPage, setSelectedPage] = useState(0);

  // Changing the advantage every 15 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      // Increment the selectedPage, and reset to 0 if it exceeds the number of advantages
      setSelectedPage((prevPage) => (prevPage + 1) % advantages.length);
    }, 15000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  // Method to generate the ProDes advantages
  const genAdvantages = () => {
    const handlePageChange = (event, page, side) => {
      if (side === "left" && page > 0) {
        setSelectedPage(page - 1);
      } else if (side === "right" && page < advantages.length - 1) {
        setSelectedPage(page + 1);
      }
    };

    return (
      <div className="advantages-container">
        <div className="left-pagination-button">
          <lord-icon
            src="https://cdn.lordicon.com/dwoxxgps.json"
            trigger="loop"
            delay="1500"
            state="hover-arrow-up-2"
            colors="primary:#ffffff"
            onClick={(event) => handlePageChange(event, selectedPage, "left")}
          ></lord-icon>
        </div>

        <div
          id="jumping-card"
          className="single-advantage"
        >
          <div className="single-advantage-title">
            {advantages[selectedPage].title}
          </div>
          <div className="single-advantage-content">
            {advantages[selectedPage].content}
          </div>
        </div>

        <div className="right-pagination-button">
          <lord-icon
            src="https://cdn.lordicon.com/dwoxxgps.json"
            trigger="loop"
            delay="1500"
            state="hover-arrow-up-2"
            colors="primary:#ffffff"
            onClick={(event) => handlePageChange(event, selectedPage, "right")}
          ></lord-icon>
        </div>
      </div>
    );
  };

  // ProDes indicator
  const genIndicator = (icon, indicator, text, index) => {
    return (
      <div className="single-indicator" key={index}>
        <div className="indicator-icon-container">{icon()}</div>
        <div className="indicator-value">{indicator}</div>
        <div className="indicator-text">{text}</div>
      </div>
    );
  };

  return (
    // Software Advantages
    <div className="advantages-section">
      <div className="advantages-title">Lo que hemos logrado</div>

      <div className="indicators">
        {listIndicators.map((ind, index) =>
          genIndicator(ind.icon, ind.indicator, ind.text, index)
        )}
      </div>

      <div className="advantages-title">
        <p>Con</p>
        <img className="logo-advantages" src={logo} alt="logo_prodet"></img>
        <p>ProDet podrás</p>
      </div>

      {genAdvantages()}

    </div>
  );
};

export default AdvantagesSection;
