// Root imports
import React, { useState } from "react";

// Style importe
import "../css/email.css";

// Email form component
const EmailForm = (props) => {
  //  Local state to handle user content
  const [userName, setName] = useState(null);
  const [userPhone, setPhone] = useState(null);
  const [userEmail, setEmail] = useState(null);
  const [comments, setComments] = useState(
    "¡Me interesa la suscripción Anual!"
  );
  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [selectedCity, setSelectedCity] = useState(0);
  const [colombiaCities, setColombiaCities] = useState([{ id: 0, name: " " }]);

  // Method to get the cities of a department
  const getColombiaCities = async (idDepartment) => {
    try {
      const response = await fetch(
        `https://api-colombia.com/api/v1/Department/${props.colombiaDepartments[idDepartment].id}/cities`
      );
      const data = await response.json();
      data.sort((a, b) => a.name.localeCompare(b.name));
      setColombiaCities(data);
    } catch (error) {
      console.error("Error fetching Colombian cities data:", error);
    }
  };

  // On mount
  if (props.colombiaDepartments !== null && colombiaCities === null) {
    getColombiaCities(selectedDepartment);
  }

  // Method for closing form
  const handleClose = () => {
    props.handleFormClose();
  };

  const handleSendMessage = () => {
    // Wpp phone number
    const phoneNumber = "573001447296";

    // Msg content
    const msgContent = `Hola 👋, me interesa ProDet
    Mi nombre es: ${userName}
    Mi número de celular es: ${userPhone}
    Mi correo electrónico es: ${userEmail}
    Trabajo en: ${colombiaCities[selectedCity].name}, ${props.colombiaDepartments[selectedDepartment].name}

    ${comments}`;

    // Construct the WhatsApp API URL
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      msgContent
    )}`;

    // Open the WhatsApp link in a new tab
    window.open(whatsappUrl, "_blank");
  };

  // Handle the change in department
  const handleDepartmentChange = (e) => {
    // Updating the value
    setSelectedDepartment(e.target.value);

    // Updating the cities
    getColombiaCities(e.target.value);
  };

  return (
    <div className="container">
      <div className="email-form">
        <div className="form-title">Ponte en contacto con nosotros</div>

        <form id="form-element">
          <div id="form-text">Ingresa tu nombre:</div>
          <input
            type="text"
            id="name-field"
            size="50"
            required
            placeholder="Tu nombre aquí"
            onChange={(e) => setName(e.target.value)}
          />

          <div id="form-text">Ingresa tu correo:</div>
          <input
            type="email"
            id="email-field"
            size="50"
            required
            placeholder="Correo electrónico"
            onChange={(e) => setEmail(e.target.value)}
          />

          <div id="form-text">Ingresa tu celular:</div>
          <input
            type="tel"
            id="tel-field"
            size="50"
            required
            placeholder="+00 0000000000"
            onChange={(e) => setPhone(e.target.value)}
          />

          <div id="form-text">
            Selecciona el departamento en el que trabajas
          </div>
          <select
            id="plans-list"
            value={selectedDepartment}
            onChange={handleDepartmentChange}
          >
            {props.colombiaDepartments !== null &&
              props.colombiaDepartments.map((department, index) => {
                return (
                  <option key={index} value={index}>
                    {department.name}
                  </option>
                );
              })}
          </select>

          <div id="form-text">Selecciona la ciudad en el que trabajas</div>
          <select
            id="plans-list"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            {colombiaCities !== null &&
              colombiaCities.map((city, index) => {
                return (
                  <option key={index} value={index}>
                    {city.name}
                  </option>
                );
              })}
          </select>

          <div id="form-text">
            Si deseas dejar algún comentario, hazlo a continuación:
          </div>
          <textarea
            rows="5"
            required
            id="text-field"
            placeholder="Deja tus comentarios"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          ></textarea>

          <div id="buttons">
            <div id="submit-button" onClick={handleSendMessage}>
              Enviar
            </div>
            <div id="close-button" onClick={handleClose}>
              Cerrar
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailForm;
