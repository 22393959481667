// Root imports
import React, { useState } from "react";

// Style for the component
import "../css/whyprodet.css";

// Static content import
import logo from "../static/logo.svg";

// Main component for services
const WhyProDetSection = () => {
  return (
    // Main container for section
    <div className="services-section">
      <div className="services-title">
        <p>¿Por qué</p>
        <img className="logo-services" src={logo} alt="logo_prodet"></img>
        <p>ProDet?</p>
      </div>

      <div className="initial-text">
        El automatizar el proceso de reforzamiento permite, además de disminuir
        la demanda de mano de obra:
      </div>

      <div className="reasons-list">
        <div className="reason">
          Explorar diferentes alternativas estructurales para tus proyectos.
        </div>
        <div className="reason">
          Explorar múltiples opciones de reforzamiento.
        </div>
        <div className="reason">
          Apoyar a tus clientes en la gestión de la construcción de la
          estructura.
        </div>
        <div className="reason">
          Facilitar los procesos de revisión por terceros.
        </div>
      </div>
    </div>
  );
};

export default WhyProDetSection;
