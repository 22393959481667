// Root imports
import React, { useRef, useEffect, useState } from "react";

// Style for component
import "../css/upper_cont.css";

// Static content import
import edif1 from "../static/edif1.PNG";
import edif2 from "../static/edif2.PNG";
import edif3 from "../static/edif3.PNG";
import edif4 from "../static/edif4.PNG";
import edif5 from "../static/edif5.PNG";
import edif6 from "../static/edif6.PNG";
import edif7 from "../static/edif7.PNG";
import edif8 from "../static/edif8.PNG";
import edif9 from "../static/edif9.PNG";
import rosca from "../static/rosca.PNG";

const UpperContent = ({ scrollTo, animate }) => {
  // Local state to handle when component is visible
  const [isVisible, setIsVisible] = useState(false);

  const imgCarruselRef = useRef(null);
  const imgWidth = 350;

  // On Mount mark the component as visible
  useEffect(() => {
    setIsVisible(true);
  }, []);

  // Effect to move picture carrusel
  useEffect(() => {
    // Declaring variable
    let requestId;
    // Function to animate scroll
    const animateScroll = () => {
      // If component is mounted
      if (imgCarruselRef.current && animate) {
        // Number of pixel to move
        const scrollAmount = 1;
        // Updating scroll amount
        imgCarruselRef.current.scrollLeft += scrollAmount;

        // If one picture has disappeared by scrolling
        if (imgCarruselRef.current.scrollLeft % imgWidth === 0) {
          // Getting current first picture
          const firstImage = imgCarruselRef.current.firstElementChild;
          // Appending first picture at the end
          imgCarruselRef.current.appendChild(firstImage.cloneNode(true));
          // Removing current first picture
          imgCarruselRef.current.removeChild(firstImage);
          // Restoring scroll to obtain infinite effect
          imgCarruselRef.current.scrollLeft -= imgWidth;
        }
      }
      // Animating loop
      requestId = requestAnimationFrame(animateScroll);
    };
    // Animating callback
    requestId = requestAnimationFrame(animateScroll);

    // On destroy
    return () => cancelAnimationFrame(requestId);
  }, [animate]);

  return (
    // Upper content section
    <div className="upper-content">
      <div
        className={`upper-content-text ${isVisible ? "show" : ""}`}
        style={{
          backgroundImage: `url(${rosca})`,
        }}
      >
        {/* ProDet slogan */}
        <div className="main-title">
          Todas las vigas y todos los nervios en un sólo click.
        </div>
        <div className="main-title">El futuro del diseño estructural.</div>

        {/* ProDet advantages */}
        <div className="second-title">Sólo posible con ProDet</div>
      </div>

      <div className={`upper-content-imgs ${isVisible ? "show" : ""}`}>
        <div className="img-carrusel-container" ref={imgCarruselRef}>
          <img src={edif1} alt="Edificio 1" />
          <img src={edif2} alt="Edificio 2" />
          <img src={edif7} alt="Edificio 7" />
          <img src={edif3} alt="Edificio 3" />
          <img src={edif4} alt="Edificio 4" />
          <img src={edif5} alt="Edificio 5" />
          <img src={edif6} alt="Edificio 6" />
          <img src={edif8} alt="Edificio 8" />
          <img src={edif9} alt="Edificio 9" />
        </div>

        {/* Call to action */}
        <div className="arrow-down-container">
          <lord-icon
            src="https://cdn.lordicon.com/rmkahxvq.json"
            trigger="loop"
            delay="1500"
            colors="primary:#ffffff"
            onClick={() => scrollTo("attributes")}
          ></lord-icon>
        </div>
      </div>
    </div>
  );
};

export default UpperContent;
