// Root imports
import React, { useState, useEffect } from "react";

// Style for the component
import "../css/testimonials.css";

// Static content import
import foto1 from "../static/mario.PNG";
import foto2 from "../static/jaramillo.PNG";
import foto3 from "../static/julito.PNG";
import logo from "../static/logo.svg";

// Third party components
import useMediaQuery from "@mui/material/useMediaQuery";

const testimonials = [
  {
    photo: foto1,
    opinion: `"Cuando fundamos R&DC aspirábamos a competir con los diseñadores
    tradicionales. Entendimos que para eso teníamos que conectar
    con los problemas reales del constructor y que no estaban siendo resueltos.
    Esto sin embargo no era posible con las herramientas disponibles en el mercado."`,
    author: "- Mario Sáenz, PhD. Director Técnico en R&DC",
  },
  {
    photo: foto2,
    opinion: `"ProDet es la respuesta a una necesidad de ingeniería
    de alta calidad, alejada de lo procedimental y cercana a lo conceptual.
    Así lo habíamos hecho durante nuestra carrera académica
    donde la computación era solo una poderosa calculadora que nos permitía dedicar
    nuestro tiempo a lo verdaderamente importante. Esa es la filosofía de ProDet."`,
    author: "- Juan Diego Jaramillo, PhD. Director de I+D en R&DC",
  },
  {
    photo: foto3,
    opinion: `"En R&DC siempre se nos inculcó la cultura de explorar varias alternativas
    de solución a los problema de diseño. Esto necesariamente había que abordarlo mediante
    el computador. Después de varios años de formación en diseño se me propuso liderar el
    proyecto de computación. Esta era una decisión difícil pero creemos que ese es el secreto
    de ProDet. Es una herramienta para Diseñadores creada por Diseñadores."`,
    author: "- Julián Parra, MsC. Director de Desarrollo de Software en R&DC",
  },
];

// Testimonial section component
const TestimonialsSection = () => {
  // Handle local state
  const [currentIndex, setCurrentIndex] = useState(0);

  // Media query to display the number of
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:999px)");
  const isDesktop = useMediaQuery("(min-width:1000px)");
  let numberTestimonials;

  // Selecting the number of testimonials that would be displayed depending on
  // the size of the screen
  if (isMobile) {
    numberTestimonials = Math.min(2, testimonials.length);
  } else if (isTablet) {
    numberTestimonials = Math.min(3, testimonials.length);
  } else if (isDesktop) {
    numberTestimonials = Math.min(4, testimonials.length);
  }
  const testsArrays = Array.from(
    { length: numberTestimonials },
    (_, index) => index
  );

  // Method to generate a single testimonial
  const genTestimonial = (photo, opinion, author, index) => {
    return (
      <div className="testimonial-card" key={index}>
        <div className="photo-plus-text">
          <img src={photo} alt="Client" className="testimonial-image" />
          <div className="testimonial-text">{opinion}</div>
        </div>
        <div className="testimonial-author">{author}</div>
      </div>
    );
  };

  useEffect(() => {
    let interval;
    if (numberTestimonials < testimonials.length) {
      // Change opinion every 15 seconds
      interval = setInterval(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + numberTestimonials) % testimonials.length
        );
      }, 15000);
    }

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="testimonials-section">
      <div className="testimonials-title">
        Creado por Ingenieros Estructurales
      </div>

      <div className="testimonials-description">
        <p>Durante años</p>
        <div className="text-and-logo">
          <img className="logo-testimonials" src={logo} alt="logo_prodet"></img>
          <p>ProDet</p>
        </div>
        <p>ha sido la herramienta de diseño en</p>
        <a
          href="https://risk-and-design.com/"
          className="rdc-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Risk and Design Consulting
        </a>{" "}
        <p>y estas son las razones por las cuales fue creado</p>
      </div>

      <div className="testimonials-container">
        {testsArrays.map((index) => {
          const indexTestimonial =
            currentIndex + index <= testimonials.length - 1
              ? currentIndex + index
              : currentIndex + index - testimonials.length;

          return genTestimonial(
            testimonials[indexTestimonial].photo,
            testimonials[indexTestimonial].opinion,
            testimonials[indexTestimonial].author,
            index
          );
        })}
      </div>
    </div>
  );
};

export default TestimonialsSection;
