// Root imports
import React, { useRef, useEffect, useState } from "react";

// Style for component
import "../css/clients.css";

// Third party components
import useMediaQuery from "@mui/material/useMediaQuery";

// Static content import
import logo1 from "../static/Logo1.png";
import logo2 from "../static/Logo2.png";
import logo3 from "../static/Logo3.png";
import logo4 from "../static/Logo4.png";
import logo5 from "../static/Logo5.png";
import logo6 from "../static/Logo6.png";
import logo7 from "../static/Logo7.png";
import logo8 from "../static/Logo8.png";
import logo9 from "../static/Logo9.png";
import logo10 from "../static/Logo10.png";
import logo11 from "../static/Logo11.png";

const Clients = ({ animate }) => {
  // Local state to handle when component is visible
  const [isVisible, setIsVisible] = useState(false);

  const imgCarruselRef = useRef(null);

  // Media query to display the number of
  const isDesktop = useMediaQuery("(min-width:600px)");
  const imgWidth = isDesktop ? 350 : 240;

  // On Mount mark the component as visible
  useEffect(() => {
    setIsVisible(true);
  }, []);

  // Effect to move picture carrusel
  useEffect(() => {
    // Declaring variable
    let requestId;
    // Function to animate scroll
    const animateScroll = () => {
      // If component is mounted
      if (imgCarruselRef.current && animate) {
        // Number of pixel to move
        const scrollAmount = 1.7;
        // Updating scroll amount
        imgCarruselRef.current.scrollLeft += scrollAmount;

        // If one picture has disappeared by scrolling
        if (imgCarruselRef.current.scrollLeft % imgWidth === 0) {
          // Getting current first picture
          const firstImage = imgCarruselRef.current.firstElementChild;
          // Appending first picture at the end
          imgCarruselRef.current.appendChild(firstImage.cloneNode(true));
          // Removing current first picture
          imgCarruselRef.current.removeChild(firstImage);
          // Restoring scroll to obtain infinite effect
          imgCarruselRef.current.scrollLeft -= imgWidth;
        }
      }
      // Animating loop
      requestId = requestAnimationFrame(animateScroll);
    };
    // Animating callback
    requestId = requestAnimationFrame(animateScroll);

    // On destroy
    return () => cancelAnimationFrame(requestId);
  }, [animate]);

  return (
    // Upper content section
    <div className="clients-section">
      <div className="main-title">Algunos de nuestros clientes</div>

      <div className={`clients-section-imgs ${isVisible ? "show" : ""}`}>
        <div className="img-carrusel-container" ref={imgCarruselRef}>
          <img src={logo1} alt="Logo 1" />
          <img src={logo2} alt="Logo 2" />
          <img src={logo7} alt="Logo 7" />
          <img src={logo3} alt="Logo 3" />
          <img src={logo4} alt="Logo 4" />
          <img src={logo5} alt="Logo 5" />
          <img src={logo6} alt="Logo 6" />
          <img src={logo8} alt="Logo 8" />
          <img src={logo9} alt="Logo 9" />
          <img src={logo10} alt="Logo 10" />
          <img src={logo11} alt="Logo 11" />
        </div>
      </div>
    </div>
  );
};

export default Clients;
