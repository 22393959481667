// Root imports
import React, { useState } from "react";

// Style for the component
import "../css/pricing.css";

// Third party components
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import FormControl from "@mui/material/FormControl";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import useMediaQuery from "@mui/material/useMediaQuery";

// Static content
import logo from "../static/logo.svg";
import rosca from "../static/rosca.PNG";

// Plans content object
const plans = {
  perHour: {
    type: "Mensual",
    features: [
      ["30 tokens", true],
      ["Soporte técnico", true],
      ["Soporte estructural", false],
    ],
  },
  monthly: {
    type: "Semestral",
    features: [
      ["300 tokens", true],
      ["Soporte técnico", true],
      ["Soporte estructural", false],
    ],
  },
  annual: {
    type: "Anual",
    features: [
      ["1000 tokens", true],
      ["Soporte técnico", true],
      ["Soporte estructural", true],
    ],
  },
};

const PricingSection = (props) => {
  // Variable to choose the type of components displayed
  const isMobile = useMediaQuery("(max-width:999px)");

  // State control to manage the select component
  const [planType, setPlanType] = useState("annual");

  // Method to update the selected plan
  const handlePlanChange = (event) => {
    setPlanType(event.target.value);
  };

  // Method to open email form
  const handleFormOpen = () => {
    props.handleFormOpen();
  };

  // Method to create the select component for the plan
  const selectPlan = () => (
    <FormControl fullWidth>
      <Select
        id="select-plan"
        value={planType}
        onChange={handlePlanChange}
        sx={{
          "& .MuiSelect-select": {
            fontFamily: "var(--font-family)",
            backgroundColor: "rgb(58, 58, 58)",
            borderRadius: "1.5rem",
          },
          "& .MuiSelect-select:focus": {
            borderRadius: "1.5rem",
          },
        }}
      >
        {Object.keys(plans).map((plan, index) => (
          <MenuItem key={index} value={plan}>
            {plans[plan].type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  // Method to generate a plan card
  const genPlanCard = (plan, index) => {
    return (
      <div className="plan-card" key={index}>
        <div className="plan-type">{plan.type}</div>

        <ul className="plan-features">
          {plan.features.map((item, index) => {
            return (
              <li key={index}>
                {item[1] ? (
                  <CheckCircleOutlineRoundedIcon
                    className="check-icon success"
                    fontSize="large"
                  />
                ) : (
                  <HighlightOffRoundedIcon
                    className="check-icon clear"
                    fontSize="large"
                  />
                )}
                {item[0]}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    // Main container for the section
    <div className="pricing-section">
      {/* title */}
      <div className="pricing-title">
        <img className="logo-plans" src={logo} alt="logo_prodet"></img>
        <p>Planes</p>
      </div>
      {/* description */}
      <div className="pricing-description">
        ¡ProDet se adapta a tus necesidades! Contáctanos si deseas cotizar un
        plan personalizado.
      </div>

      {/* Select component for the plan */}
      {isMobile && <div className="select-plan-container">{selectPlan()}</div>}

      {/* Plan and pricing cards */}
      {isMobile ? (
        <div className="pricing-cards">{genPlanCard(plans[planType], 0)}</div>
      ) : (
        <div className="pricing-cards">
          {Object.keys(plans).map((plan, index) =>
            genPlanCard(plans[plan], index)
          )}
        </div>
      )}

      {/* Request your plan */}
      <div onClick={handleFormOpen} className="button">
        ¡INSCRÍBETE AQUÍ!
      </div>

      <img
        className="background-pricing"
        src={rosca}
        alt="logo_rosca"
      ></img>
    </div>
  );
};

export default PricingSection;
