// Root imports
import React, { useState, useEffect } from "react";

// Style for the component
import "../css/attributes.css";

// Static content import
import logo from "../static/logo.svg";

// Third party components
import useMediaQuery from "@mui/material/useMediaQuery";

// Array with main features of ProDes
const attributes = [
  {
    title: "Iniciar tu empresa",
    description: `Comenzar ya tu propia empresa de Ingeniería Estructural generando todos los planos de vigas y nervios en un sólo click.`,
  },
  {
    title: "Detallar como experto",
    description: `Diseñar y competir como los ingenieros expertos generando de forma automática despieces con un alto nivel de detalle.`,
  },
  {
    title: "Modernizar la práctica ingenieril",
    description: `Marcar la diferencia y agregar valor a tus clientes a través de una ingeniería moderna basada en la computación de alto desempeño.`,
  },
  {
    title: "Configurar el nivel de detallado",
    description: `Utilizar plantillas de despiece predefinidas o personalizar tus propias 
      prácticas de detallado. Agregar valor desde el despiece usando múltiples 
      criterios de reforzamiento de los elementos.`,
  },
  {
    title: "Diseñar para Múltiples escenarios",
    description: `Despiezar el edificio para envolventes de escenarios, evitando reprocesos y eliminando 
      la necesidad de generación de nuevos modelos.`,
  },
  {
    title: "Diseñar para el constructor",
    description: `Seleccionar el diseño de menor número de operaciones en obra o el más fácil de construir, o el que mejor se acomode a la logística del proyecto.`,
  },
];

const AttributesSection = () => {
  // Variable to choose the type of components displayed
  const isMobile = useMediaQuery("(max-width:600px)");

  // Handle local state
  const [selectedPage, setSelectedPage] = useState(0);

  // Changing the card every 5 seconds in the mobile version
  useEffect(() => {
    const timer = setInterval(() => {
      // Increment the selectedPage, and reset to 0 if it exceeds the number of advantages
      setSelectedPage((prevPage) => (prevPage + 1) % attributes.length);
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handlePageChange = (event, page, side) => {
    if (side === "left" && page > 0) {
      setSelectedPage(page - 1);
    } else if (side === "right" && page < attributes.length - 1) {
      setSelectedPage(page + 1);
    }
  };

  return (
    <div className="attributes-section">
      <div className="attributes-title">
        <p>Con</p>
        <img
          className="logo-attributes"
          src={logo}
          alt="logo_prodet"
        ></img>
        <p>ProDet podrás</p>
      </div>
      {!isMobile ? (
        <div className="attributes-grid">
          {attributes.map((service, index) => (
            <div
              key={index}
              id="jumping-card"
              className={`card ${
                index % 2 === 0 ? "card-primary" : "card-secondary"
              } `}
            >
              <div className="first-content">{service.title}</div>
              <div className="second-content">{service.description}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className="attributes-pagination">
          <div
            id="jumping-card"
            className={`card ${
              selectedPage % 2 === 0 ? "card-primary" : "card-secondary"
            } `}
          >
            <div className="first-content">
              {attributes[selectedPage].title}
            </div>
            <div className="second-content">
              {attributes[selectedPage].description}
            </div>
          </div>
          <div className="pagination-buttons">
            <div className="left-pagination-button">
              <lord-icon
                src="https://cdn.lordicon.com/dwoxxgps.json"
                trigger="loop"
                delay="1500"
                state="hover-arrow-up-2"
                colors="primary:#424242"
                onClick={(event) =>
                  handlePageChange(event, selectedPage, "left")
                }
              ></lord-icon>
            </div>
            <div className="right-pagination-button">
              <lord-icon
                src="https://cdn.lordicon.com/dwoxxgps.json"
                trigger="loop"
                delay="1500"
                state="hover-arrow-up-2"
                colors="primary:#424242"
                onClick={(event) =>
                  handlePageChange(event, selectedPage, "right")
                }
              ></lord-icon>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AttributesSection;
