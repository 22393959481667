// Root imports
import React from "react";

// Third party component
import YouTubeIcon from "@mui/icons-material/YouTube";

// Style for the component
import "../css/video_comp.css";

// Importing static content
import video1 from "../static/video_nuevo.mp4";

const VideoComp = () => {
  return (
    <div className="text-plus-video-container">
      <div className="main-titles">
        <div className="title">Conoce el flujo de trabajo:</div>
        <div className="brief">Sencillo, Rápido, Intuitivo.</div>
      </div>

      <div className="inner-content">
        <div className="text-container">
          <div className="list-container">
            <ol className="text-line" type="1">
              <li>Paso 1: Cree su proyecto.</li>
              <li>Paso 2: Perfeccione la geometría.</li>
              <li>Paso 3: Defina criterios de despiece.</li>
              <li>Paso 4: Imprima planos para obra.</li>
            </ol>
          </div>

          {/* Button to subscribe to the Youtube channel */}
          <div className="button-container">
            <a
              href="https://www.youtube.com/channel/UCem82-GYG6mkfxwpTtt2gDw"
              target="_blank"
              rel="noopener noreferrer"
              className="subscribe-button"
            >
              <div className="button-text">¡SUSCRÍBETE!</div>
              <YouTubeIcon fontSize="large" className="media-icon" />
            </a>
          </div>
        </div>

        <div className="video-container">
          <video controls>
            <source src={video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default VideoComp;
